import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../scss/style.css";
import "../js/additional.js";
import meet_up_logo from "../assets/images/meet_up_logo.png";
import img_user_profile from "../assets/images/img_user_profile.jpg";
import img_slide_01 from "../assets/images/img_slide_01.png";
import img_slide_02 from "../assets/images/img_slide_02.png";
import img_slide_03 from "../assets/images/img_slide_03.png";

const MeetLogin = () => {
  const navigate = useNavigate();
  const domain = window && window.location.origin;
  function generateRandomChannelName() {
    const adjectives = ["quick", "bright", "sharp", "clever", "brave"];
    const nouns = ["eagle", "lion", "tiger", "panther", "wolf"];
    const randomAdjective =
      adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
    const randomNumber = Math.floor(Math.random() * 10000);
    return `${randomAdjective}-${randomNoun}-${randomNumber}`;
  }
  const channelName = generateRandomChannelName();
  const handleClickMeeting = () => {
    navigate(`/start?c=${channelName}`);
  };

  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  // Function to format the time as 11:35 PM
  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesFormatted} ${ampm}`;
  };

  // Function to format the date as Sat, Aug 3
  const formatDate = (date) => {
    const options = { weekday: "short", month: "short", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  useEffect(() => {
    const date = new Date();
    const time = formatTime(date);
    const formattedDate = formatDate(date);
    setCurrentTime(time);
    setCurrentDate(formattedDate);
  }, []);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${domain + "/#/start?c=" + channelName}`)
      .then(() => {
        // Optionally, you can add a notification or feedback here
        alert(`Copied invite link: ${domain + "/#/start?c=" + channelName}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div className='meet-up-home'>
      <div className='meet-up-header'>
        <div className='meet-up-header-inner'>
          <div className='meet-up-logo'>
            <img src={meet_up_logo} alt='img' />
            <h5>
              <strong>Meet</strong> Up
            </h5>
          </div>
          <div className='user-login'>
            <div className='user-login-assets'>
              <ul className='navi-bar'>
                <li className='data-time'>
                  <span>{currentTime}</span>
                  <span> • </span>
                  <span>{currentDate}</span>
                </li>
                <li>
                  <a href='javascript:void(0)' className='rounded-circle' data-bs-toggle="modal" data-bs-target="#settingModal">
                    <i className='fa-solid fa-gear'></i>
                  </a>
                </li>
                {/* <li>
                  <a href='#' className='rounded-circle'>
                    <i className='fa-regular fa-circle-question'></i>
                  </a>
                </li>
                <li>
                  <a href='#' className='rounded-circle'>
                    <i className='fa-solid fa-message'></i>
                  </a>
                </li>
                <li className='custom-dropdown ms-4'>
                  <div className='dropdown-end'>
                    <a className='dropdown-toggle' data-bs-toggle='dropdown'>
                      <img src={img_user_profile} alt='img' />
                    </a>
                    <ul className='dropdown-menu dropdown-menu-customize dropdown-menu-end'>
                      <li>
                        <a className='dropdown-item' href='#'>
                          Action
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* ========================== */}
      <div className="modal fade" id="settingModal" tabindex="-1" aria-labelledby="settingModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="settingModalLabel">Settings</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="audio-tab" data-bs-toggle="tab" data-bs-target="#audio" type="button" role="tab" aria-controls="audio">
                    <i className="mice-on fa-solid fa-microphone"></i>
                    <span>audio</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video" type="button" role="tab" aria-controls="video">
                    <i className="video-on fa-solid fa-video"></i>
                    <span>video</span>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="audio" role="tabpanel" aria-labelledby="audio-tab">
                  <div className="mb-3">
                    <label className="form-label fw-bold">Microphone</label>
                    <select class="form-select">
                      <option selected>Microphone Array (Realtek(R) Audio)</option>
                      <option value="Another One">Another One</option>
                    </select>
                  </div>
                </div>
                <div className="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
                  <div className="mb-3">
                    <label className="form-label fw-bold">Camera</label>
                    <select class="form-select">
                      <option selected>HD Camera</option>
                      <option value="Another One">Another Camera</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========================== */}
      <div className='meet-up-content'>
        <div className='meet-up-inner-content'>
          <div className='row w-100 align-items-center'>
            <div className='meeting-code col-md-6'>
              {/* <h1>Video calls and meetings for everyone</h1>
              <h3>
                Connect, collaborate, and celebrate from anywhere with{" "}
                <strong>Meet Up</strong>
              </h3> */}
              <div className='create-meeting'>
                <div className='dropdown-end'>
                  <a
                    className='dropdown-toggle primary-bg-color user-meeting-create'
                    data-bs-toggle='dropdown'>
                    <i className='fa-solid fa-user-plus'></i>
                    <span className='ms-2'>New Meeting</span>
                  </a>
                  <ul className='dropdown-menu dropdown-menu-customize'>
                    {/* <li>
                      <a className='dropdown-item' href='#'>
                        <i className='ms-2 me-3 fa-solid fa-link'></i> Create a
                        meeting for later
                      </a>
                    </li> */}
                    <li>
                      <p
                        className='dropdown-item'
                        onClick={() => handleClickMeeting()}>
                        <i className='ms-2 me-3 fa-solid fa-plus'></i> Start an
                        instant meeting
                      </p>
                    </li>
                    {/* <li>
                      <a className='dropdown-item' href='#'>
                        <i className='ms-2 me-3 fa-regular fa-calendar'></i>{" "}
                        Schedule in Google Calendar
                      </a>
                    </li> */}
                  </ul>
                </div>
                <form className='meeting-form'>
                  <div className='position-relative'>
                    <i className='fa-regular fa-keyboard'></i>
                    <input
                      type='text'
                      placeholder='Enter a code or link'
                      value={channelName}
                    />
                  </div>
                  <div className='position-relative'>
                    <button
                      type='button'
                      className='primary-bg-gradient user-meeting-create'
                      //className='disabled'
                      onClick={() => handleClickMeeting()}>
                      Start
                    </button>
                    <button
                      type='button'
                      onClick={() => handleCopy()}>
                      Copy link
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className='meet-up-slider col-md-6'>
              <div className='meeting-sliders'>
                <div className='slider-content'>
                  <img src={img_slide_01} alt='img' />
                  <h4>Get a link you can share</h4>
                  <p>
                    Click <strong>New meeting</strong> to get a link you can
                    send to people you want to meet with
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetLogin;
