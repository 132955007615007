import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
  LocalUser,
  RemoteUser,
  useRemoteVideoTracks,
} from "agora-rtc-react";
import axios from "axios";
import img_user_profile from "../assets/images/img_user_profile.jpg";
import meet_up_logo from "../assets/images/meet_up_logo.png";

function MeetHome() {
  const location = useLocation();
  const domain = window && window.location.origin;
  // Function to extract channelName from URL
  const queryParams = new URLSearchParams(location.search);
  const channelN = queryParams.get("c");
  const authUser = queryParams.get("authUser");

  const [isLocal, setLocal] = useState(false);
  const [calling, setCalling] = useState(true);
  const isConnected = useIsConnected();
  const [appId, setAppId] = useState("");
  const [channel, setChannel] = useState(channelN);
  const [token, setToken] = useState("");
  const [shouldJoin, setShouldJoin] = useState(false);
  const [username, setUsername] = useState(authUser);
  let APPID = "bc85d176d4394ea4b29e944d40f2bf30";
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get(
        //   "https://videocall.mobappstesting.website/credentials.json"
        // );
        // const data = response.data;
        // if (
        //   data &&
        //   data.app_id &&
        //   data.channel_name &&
        //   data.app_token &&
        //   channel
        // ) {
        //   const rtcToken = await axios.get(
        //     `https://api-dev.u4u.pw/rtcToken?channelName=${channel}`
        //   );
        //   if (rtcToken && rtcToken.data && rtcToken.data.token) {
        //     setAppId(data.app_id);
        //     setToken(rtcToken.data.token);
        //     setShouldJoin(true);
        //   }
        // }

        const rtcToken = await axios.get(
          `https://api-dev.u4u.pw/rtcToken?channelName=${channel}`
        );
        if (rtcToken && rtcToken.data && rtcToken.data.token) {
          setAppId(APPID);
          setToken(rtcToken.data.token);
          setShouldJoin(true);
        }
      } catch (error) {
        console.error("Error fetching the config file:", error);
      }
    };
    fetchData();
  }, []);

  const generateUid = () => Math.floor(Math.random() * 65535);
  useJoin(
    {
      appid: appId,
      channel: channel,
      token: token || null,
      uid: username,
    },
    shouldJoin // Join conditionally based on shouldJoin state
  );

  // Local user
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);

  const [currentTime, setCurrentTime] = useState("");

  // Function to format the time as 11:35 PM
  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesFormatted} ${ampm}`;
  };

  useEffect(() => {
    const date = new Date();
    const time = formatTime(date);
    setCurrentTime(time);
  }, []);

  // Remote users
  const remoteUsers = useRemoteUsers();

  useEffect(() => {
    if (isConnected) {
      setLocal(true);
      console.log("Connected to the channel.");
    } else {
      console.error("Not connected to the channel.");
    }
  }, [isConnected]);

  useEffect(() => {
    if (localMicrophoneTrack) {
      localMicrophoneTrack
        .setEnabled(micOn)
        .then(() => {
          console.log(`Microphone is ${micOn ? "enabled" : "disabled"}.`);
        })
        .catch((error) => {
          console.error("Error enabling/disabling microphone:", error);
        });
    }
  }, [localMicrophoneTrack, micOn]);

  useEffect(() => {
    if (localCameraTrack) {
      localCameraTrack
        .setEnabled(cameraOn)
        .then(() => {
          console.log(`Camera is ${cameraOn ? "enabled" : "disabled"}.`);
        })
        .catch((error) => {
          console.error("Error enabling/disabling camera:", error);
        });
    }
  }, [localCameraTrack, cameraOn]);

  useEffect(() => {
    remoteUsers.forEach((user) => {
      if (user.audioTrack) {
        user.audioTrack.play();
      }
      if (
        user.videoTrack &&
        !document.getElementById(`remote-video-${user.uid}`)
      ) {
        console.log(`Playing video for user ${user.uid}`);
        user.videoTrack.play(`remote-video-${user.uid}`);
      } else if (!user.videoTrack) {
        console.log(`No video track for user ${user.uid}`);
      }
    });
  }, [remoteUsers]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setCalling(false);
    navigate(`/`);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${domain + "/#/start?c=" + channel}`)
      .then(() => {
        // Optionally, you can add a notification or feedback here
        alert(`Copied invite link: ${domain + "/#/start?c=" + channel}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div className='start-meeting-wrap'>
      <div className='start-meeting-inner-wrap'>
        <div className='metting-members'>
          {/* Use this class when one use is logged in - other-user-meet-view as user (meet-users-count-1) in this class metting-inner-members*/}
          {/* Use this class when two use is logged in - meet-users-count-2 in this class metting-inner-members */}
          <div
            className={`metting-inner-members ${remoteUsers.length === 1
              ? "other-user-meet-view"
              : remoteUsers.length > 1
                ? `other-user-meet-view meet-users-count-${remoteUsers.length}`
                : ""
              }`}>
            <LocalUser
              cameraOn={cameraOn}
              micOn={micOn}
              videoTrack={localCameraTrack}
              cover={img_user_profile}
              className='logged-in-user-view self-login-user'>
              <div className='mice-activetion'>
                <i
                  className={`fa-solid ${micOn ? "fa-microphone" : "fa-microphone-slash"
                    }`}></i>
              </div>
            </LocalUser>
            {remoteUsers.map((user) => (
              <div className='logged-in-user-view' key={user.uid}>
                <div className='mice-activetion'>
                  <i className='fa-solid fa-microphone'></i>
                  <i className='fa-solid fa-microphone-slash'></i>
                </div>
                <div className='user'>
                  <div
                    id={`remote-video-${user.uid}`}
                    className='remote-video'></div>
                  <RemoteUser
                    cover={img_user_profile}
                    user={user}
                    className='logged-in-user-profile-video'
                  />
                  <div className='logged-user-name'>{user.uid}</div>
                </div>
              </div>
            ))}
          </div>


          <div class="chat-member-room"><div class="close-chat-room"><a href="#"><i class="fa-solid fa-xmark"></i></a></div><div class="info-room-box"><div class="chat-member-room-head"><h5>Meeting Details</h5></div><div class="chat-member-room-details"><h6>Joining info</h6><p class="join-link">https://meet.google.com/zav-eiqy-nhd</p><a href="#" class="join-copy-link"><i class="fa-solid fa-copy"></i> Copy joining info</a></div></div></div>



        </div>
        <div className='meeting-actions-keys'>
          <div className='inner-keys-featurs'>
            <ul className='time-link'>
              <li>{currentTime}</li>
              <li style={{ cursor: "pointer" }} onClick={handleCopy}>
                {channel}
                <p>Click to share Invite link</p>
              </li>
            </ul>

            <div className='video-views-actions'>
              <div className='meet-video-actions'>
                <a
                  href='javascript:void(0);'
                  className='mice-btn'
                  onClick={() => setMic(!micOn)}>
                  <i
                    className={`mice-on fa-solid ${micOn ? "fa-microphone" : "fa-microphone-slash"
                      }`}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title={
                      micOn ? "Turn off microphone" : "Turn on microphone"
                    }></i>
                </a>
                <a
                  href='javascript:void(0);'
                  className='video-btn'
                  onClick={() => setCamera(!cameraOn)}>
                  <i
                    className={`video-on fa-solid ${cameraOn ? "fa-video" : "fa-video-slash"
                      }`}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title={
                      cameraOn ? "Turn off camera" : "Turn on camera"
                    }></i>
                </a>
                {/* <a href='#' className='cc-btn'>
                  <i
                    className='fa-regular fa-closed-captioning'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Turn on captions'></i>
                </a>
                <a href='#' className='smily-btn'>
                  <i
                    className='fa-regular fa-face-smile'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Send a reaction'></i>
                </a> */}
                <a href='javascript:void(0);' className='screen-share-btn'>
                  <i
                    className='fa-solid fa-laptop'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Present now'></i>
                </a>
                {/* <a href='#' className='hand-btn'>
                  <i
                    className='fa-regular fa-hand'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Raise hand'></i>
                </a>
                <a href='#' className='more-btn'>
                  <i
                    className='fa-solid fa-ellipsis-vertical'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='More options'></i>
                </a> */}
                <a href='javascript:void(0);' className='call-end-btn'>
                  <i
                    className='fa-solid fa-phone'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-title='Leave call'
                    onClick={() => handleCancel()}></i>
                </a>
              </div>
            </div>
            <div className="chat-action-open">
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
            <div className='video-chat-actions'>
              <a href='javascript:void(0);'>
                <i
                  className='fa-solid fa-circle-info'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title='Meeting details'></i>
              </a>
              <a href='javascript:void(0);'>
                <i
                  className='fa-solid fa-users'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title='Show everyone'></i>
                <span>{remoteUsers.length + 1}</span>
              </a>
              <a href='javascript:void(0);'>
                <i
                  className='fa-solid fa-message'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-title='Chat with everyone'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetHome;
