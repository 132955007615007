import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import "../scss/style.css";
import "../js/additional.js";
import img_user_profile from "../assets/images/img_user_profile.jpg";
import meet_up_logo from "../assets/images/meet_up_logo.png";

const MeetIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [cameraOn, setCameraOn] = useState(true);
  const [micOn, setMicOn] = useState(true);
  const [username, setUsername] = useState("");
  const getChannelNameFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("c");
  };

  useEffect(() => {
    // Initialize Agora client
    const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    // Initialize video and audio tracks
    const initDevices = async () => {
      const videoTrack = await AgoraRTC.createCameraVideoTrack();
      const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      setLocalVideoTrack(videoTrack);
      setLocalAudioTrack(audioTrack);
      videoTrack.play("local-video");
    };

    initDevices();

    // Cleanup when the component is unmounted
    return () => {
      if (localVideoTrack) localVideoTrack.stop();
      if (localAudioTrack) localAudioTrack.stop();
    };
  }, []);

  const toggleCamera = () => {
    if (localVideoTrack) {
      if (cameraOn) {
        localVideoTrack.stop();
      } else {
        localVideoTrack.play("local-video");
      }
      setCameraOn(!cameraOn);
    }
  };

  const toggleMic = () => {
    if (localAudioTrack) {
      localAudioTrack.setEnabled(!micOn);
      setMicOn(!micOn);
    }
  };

  const handleJoinMeeting = () => {
    const c = getChannelNameFromUrl();
    if (c) {
      console.log(`Joining channel: ${c}`);
      navigate(`/home?c=${c}&authUser=${username}`);
    } else {
      console.error("No channel name found in URL.");
    }
  };

  const handleChange = (e) => {
    setUsername(e.target.value);
  };
  return (
    <div className='meet-up-home'>
      <div className='meet-up-header'>
        <div className='meet-up-header-inner'>
          <div className='meet-up-logo'>
            <img src={meet_up_logo} alt='img' />
            <h5>
              <strong>Meet</strong> Up
            </h5>
          </div>
          {/* <div className='user-login'>
            <div className='user-login-assets'>
              <ul className='navi-bar'>
                <li>
                  <a
                    href='#'
                    className='rounded-pill px-4 py-2 h-auto lh-base w-auto primary-bg-gradient'>
                    Sign in
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      <div className='meet-up-content'>
        <div className='meet-up-inner-content'>
          <div className='row w-100'>
            <div className='col-md-8'>
              <div className='video-chat'>
                <div className='video-chat'>
                  <div className='gradient-top'></div>
                  <div className='gradient-bottom'></div>
                  {/* <div className='login-user-name'>Logged in user name</div> */}
                  <div className='open-close-camera'>
                    <span className='camera-start'>
                      {cameraOn ? "Camera is on" : "Camera is off"}
                    </span>
                  </div>
                  <div id='local-video' className='tranparent-layer'></div>
                  <div className='meet-video-actions'>
                    <a
                      href='javascript:void(0);'
                      className='mice-btn'
                      onClick={toggleMic}>
                      <i
                        className={`${
                          micOn
                            ? "mice-on fa-solid fa-microphone"
                            : "mice-off fa-solid fa-microphone-slash"
                        }`}></i>
                    </a>
                    <a
                      href='javascript:void(0);'
                      className='video-btn'
                      onClick={toggleCamera}>
                      <i
                        className={`${
                          cameraOn
                            ? "video-on fa-solid fa-video"
                            : "video-off fa-solid fa-video-slash"
                        }`}></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='user-presentation'>
                <h2>Ready to join?</h2>
                <div className='meeting-form position-relative'>
                  <input
                    type='text'
                    name='username'
                    placeholder='Enter name'
                    value={username}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <button
                  type='button'
                  className='primary-bg-gradient'
                  onClick={() => handleJoinMeeting()}>
                  Join now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetIn;